import React from "react"
import Layout from "../components/layout"
import DynamicComponent from "../components/dynamic-components/dynamic-component"
import SectionColoumns from "../components/elements/section-columns"
import { graphql } from "gatsby"
import Article from "../components/dynamic-components/article/article"
import AllTopics from "../components/dynamic-components/all-topics"
import SEO from "../components/seo"

const ArticleCategoryPage = ({ data, pageContext, location }) => {
  const { components, page_title } = data.sanityArticleCategoryPage
  const { allSanityArticle, allSanityRecipe } = data
  return (
    <Layout>
      <SEO title={page_title} url={location.pathname} />
      <DynamicComponent components={components} />
      <SectionColoumns isCentered isMultiline>
        {pageContext.slug === "recipe" ? (
          <>
            {allSanityRecipe.nodes.map(article => {
              return (
                <div
                  key={article._id}
                  style={{ marginBottom: "3rem" }}
                  className="column is-4"
                >
                  <Article {...article} />
                </div>
              )
            })}
          </>
        ) : (
          <>
            {allSanityArticle.nodes.map(article => {
              return (
                <div
                  key={article._id}
                  style={{ marginBottom: "3rem" }}
                  className="column is-4"
                >
                  <Article
                    _type={article._type}
                    title={article.article_title}
                    image={article.article_image}
                    slug={article.slug}
                    meta={article.article_meta_data}
                  />
                </div>
              )
            })}
          </>
        )}
      </SectionColoumns>
      <AllTopics />
    </Layout>
  )
}

export default ArticleCategoryPage

export const query = graphql`
  query($slug: String) {
    allSanityRecipe {
      nodes {
        _id
        slug {
          current
        }
        _type
        title: recipe_title
        meta: recipe_meta_data {
          article_type_ref {
            article_type_title
          }
        }
        image: recipe_image_image {
          video
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allSanityArticle(
      filter: {
        article_meta_data: {
          article_type_ref: { slug: { current: { eq: $slug } } }
        }
      }
    ) {
      nodes {
        _type
        _id
        article_meta_data {
          article_type_ref {
            article_type_title
          }
        }
        article_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        article_title
        slug {
          current
        }
      }
    }

    sanityArticleCategoryPage(
      article_type_ref: { slug: { current: { eq: $slug } } }
    ) {
      page_title
      components {
        ... on SanityHeroSimple {
          _type
          _key
          cta_title
          title
          collection_reference {
            featured_image {
              asset {
                fluid {
                  src
                }
              }
            }
            slug {
              current
            }
            color {
              section_background
            }
          }
        }
        ... on SanityHeroBanner {
          _key
          _type
          hero_title
          hero_cta {
            cta_text
            cta_link {
              ... on SanityArticle {
                _type
                slug {
                  current
                }
              }
              ... on SanityCollection {
                slug {
                  current
                }
                _type
              }
              ... on SanityProduct {
                slug {
                  current
                }
                _type
              }
              ... on SanityRecipe {
                slug {
                  current
                }
                _type
              }
            }
          }
          hero_primary_section {
            pitch
            ref {
              ... on SanityArticle {
                article_image {
                  asset {
                    fluid(maxWidth: 1000) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                article_title
                slug {
                  current
                }
                _type
              }
              ... on SanityProduct {
                product_collection {
                  slug {
                    current
                  }
                }
                _type
                slug {
                  current
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    url
                  }
                }
              }
              ... on SanityCollection {
                id
                _type
                slug {
                  current
                }
                featured_image {
                  asset {
                    fluid {
                      src
                    }
                  }
                }
                color {
                  section_background
                }
              }
            }
          }
          hero_secondary_section {
            pitch
            ref {
              ... on SanityArticle {
                slug {
                  current
                }
                _type
                article_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_title
                article_image {
                  asset {
                    fluid(maxWidth: 500) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityProduct {
                _type
                slug {
                  current
                }
                product_collection {
                  slug {
                    current
                  }
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    fluid(maxWidth: 500) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityCollection {
                id
                slug {
                  current
                }
                featured_image {
                  asset {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityProductTeaser {
          _type
          _key
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityCollection {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityDynamicPage {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityProduct {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityRecipe {
                  _type
                  slug {
                    current
                  }
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
          refs {
            _id
            product_name
            slug {
              current
            }
            _type
            product_variants {
              variant_type
              variants {
                _key
                variant_info_price
                variant_info_value
              }
            }
            product_collection {
              slug {
                current
              }
            }
            product_label {
              label_title
              label_color {
                section_background
              }
            }
            product_image {
              asset {
                fluid(maxWidth: 500) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            product_disabled
          }
        }
        ... on SanitySectionIntroText {
          _key
          _type
          section_link_text
          section_text
          section_title
          section_color {
            section_background
          }
          section_link {
            ... on SanityArticle {
              id
              slug {
                current
              }
              _type
            }
            ... on SanityProduct {
              id
              _type
              slug {
                current
              }
            }
          }
        }
        ... on SanityArticleTeaser {
          _key
          _type
          article_teaser_references {
            ... on SanityArticle {
              _id
              slug {
                current
              }
              title: article_title
              meta: article_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              _type
              image: article_image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityRecipe {
              _id
              slug {
                current
              }
              _type
              title: recipe_title
              meta: recipe_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              image: recipe_image_image {
                video
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityArticleType {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityCollection {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityDynamicPage {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityProduct {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityRecipe {
                  slug {
                    current
                  }
                  _type
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
        }
        ... on SanityPageTitle {
          _key
          _type
          page_title_header
          page_title_intro
          topics {
            topics {
              article_type_title
              slug {
                current
              }
            }
          }
        }
        ... on SanityAllTopics {
          _key
          _type
        }
        ... on SanityCollectionTeaser {
          _key
          _type
          collection_references {
            color {
              section_background
            }
            featured_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            slug {
              current
            }
            pitch
            collection_title
            _id
          }
        }
      }
    }
  }
`
